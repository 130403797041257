// Navigation toggle
(() => {
	const navToggle = document.querySelector(".js-nav-toggle");
	const nav = document.querySelector("body > nav");

	// Toggle logic
	(() => {
		navToggle.addEventListener("click", () => {
			nav.classList.toggle("hidden");
		});
	})();

	// Never show nav toggle for desktops
	// (sync with CSS media breakpoint in "main.less")
	(() => {
		if (window.innerWidth >= 768) {
			nav.classList.remove("hidden");
		}

		window.addEventListener("resize", () => {
			if (window.innerWidth >= 768) {
				nav.classList.remove("hidden");
			}
		});
	})();
})();